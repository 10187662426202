<script>
import BaseImage from '../components/BaseImage.vue'
export default {
  name: 'HomePage',
  components: {
    BaseImage
  },
  page: {
    title: 'Αρχική'
  }
}
</script>

<template>
  <div class="main-banner">
    <b-container class="bv-example-row">
      <b-row>
        <b-col sm="12">
          <BaseImage class="img-fluid" src="img/ignite-game-img.svg"/>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <h1 class="main-banner-text">
            Ανάπτυξη Καινοτομικής Εφαρμογής Παιγνιοποίησης στο πεδίο της
            Επαγγελματικής Κατάρτισης και Εκπαίδευσης
          </h1>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss">
@use './../assets/scss/abstracts' as *;

.main-banner {
  padding: $s-xl 0;
  text-align: center;
}

.main-banner-text {
  font-family: "Waiting for the Sunrise", cursive;
  font-size: $s-xl;
  padding: $s-l 0;
}
</style>
